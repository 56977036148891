import React, { useEffect } from "react";
import { Table } from "antd";

const issuingType = (type) => {
    return type === "personal"
        ? "个人"
        : type === "enterprise"
            ? "企业"
            : "事业单位";
};

const invoiceType = (type) => {
    return type === "vatOrdinaryInvoice" ? "增值税普通发票" : "增值税专用发票";
};

const columns = [
    {
        title: "发票抬头",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "开具类型",
        dataIndex: "issuing_type",
        key: "issuing_type",
        render: (text) => {
            return <span>{issuingType(text)}</span>;
        },
    },
    {
        title: "发票类型",
        dataIndex: "invoice_type",
        key: "invoice_type",
        render: (text) => {
            return <span>{invoiceType(text)}</span>;
        },
    },
];

const columns_1 = [
    {
        title: "统一社会信用代码",
        dataIndex: "tax_number",
        key: "tax_number",
    },
    {
        title: "开户银行",
        dataIndex: "bank_of_deposit",
        key: "bank_of_deposit",
    },
    {
        title: "基本开户账号",
        dataIndex: "bank_account",
        key: "bank_account",
    },
    {
        title: "注册场所地址",
        dataIndex: "company_address",
        key: "company_address",
    },
    {
        title: "注册固定电话",
        dataIndex: "work_phone",
        key: "work_phone",
    },
];

const InvoiceShow = ({invoiceInfo}) => {
    useEffect(() => {
        if (invoiceInfo.issuing_type !== "personal") {
            columns.push.apply(columns, columns_1);
        }
    }, []);
    return (
        <Table
            dataSource={[invoiceInfo]}
            columns={columns}
            key="id"
            pagination={false}
        />
    );
};

export default InvoiceShow;
