import {
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined,
  } from "@ant-design/icons";
  import { Tag } from "antd";

  export function statusTag(status) {
    switch (status) {
      case "BILLING_PROGRESS":
        return (
          <Tag icon={<SyncOutlined spin />} color="geekblue">
            开票中
          </Tag>
        );
      case "BILLING_COMPLETED":
        return (
          <Tag icon={<CheckCircleOutlined />} color="purple">
            开票完成
          </Tag>
        );
      case "TRADE_SUCCESS":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            支付成功
          </Tag>
        );
      case "TRADE_CLOSED":
        return (
          <Tag icon={<CloseCircleOutlined />} color="default">
            交易关闭
          </Tag>
        );
      default:
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            待付款
          </Tag>
        );
    }
  }

export function getBreadcrumb(pathname, apps) {
    let result = []
    if (pathname.includes("home")) {
        result.push("主页")
    }
    else if (pathname.includes("order-center")) {
        result.push("订单中心")
    } else if (pathname.includes("order-app") || pathname.includes("application")) {
        result.push("应用");
        for (let app of apps) {
            if (pathname.includes(app.route_path)) {
                result.push(app.name)
            }
        }
    } else if (pathname.includes("Dashboard")) {
        result.push("仪表盘")
    } else if (pathname.includes("personal-center")) {
        result.push("个人设置")
    } else if (pathname.includes("system-log")) {
        result.push("系统日志")
    } else if (pathname.includes("invoice-management")) {
        result.push("发票管理")
    }


    return result
}

export function timeComparison(timeStr){
  let nowDate = new Date()
  const date = new Date(Date.parse(timeStr))
  return date - nowDate > 0
}