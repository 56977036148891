import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom'
import './App.css'


import Login from './pages/Login'
import Register from './pages/Register'
import ServiceTerm from './pages/ServiceTerm'

import BaseLayout from './pages/layouts/BaseLayout'

import Dashboard from '../src/pages/Dashboard'
import Wecom from './pages/Wecom'
import Lark from './pages/Lark'
import ZohoToken from "./pages/ZohoToken";

import PaymentPage from "./pages/PaymentPage";

import PersonalCenter from '../src/pages/PersonalCenter'
import OrderCenter from "./pages/OrderCenter";
import SystemLog from "./pages/SystemLog";
import InvoiceManagement from "./pages/InvoiceManagement";
import BomManagement from "./pages/BomManagement";
import Middle from "./pages/Middle";
import WechatAccount from "./pages/WechatAccount"
import YunZhiJia from './pages/YunZhiJIa'
import FileManagement from "./pages/FileManagement";

import NoMatch from '../src/pages/NoMatch'


const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/Dashboard" /> } />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/service-term" component={ServiceTerm} />
        <BaseLayout>
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/personal-center" component={PersonalCenter} />
            <Route exact path="/order-center" component={OrderCenter} />
            <Route exact path="/invoice-management" component={InvoiceManagement} />
            <Route exact path="/system-log" component={SystemLog} />
            <Route exact path="/application/wecom" component={Wecom} />
            <Route exact path="/application/lark" component={Lark} />
            <Route exact path="/application/zoho-token" component={ZohoToken} />
            <Route exact path="/application/bom-management" component={BomManagement} />
            <Route exact path="/application/middle" component={Middle} />
            <Route exact path="/application/wechat-official" component={WechatAccount} />
            <Route exact path="/application/wechat-subscribe" component={WechatAccount} />
            <Route exact path="/application/yunzhijia" component={YunZhiJia} />
            <Route exact path="/application/file-management" component={FileManagement} />
            <Route exact path="/pay" component={PaymentPage} />
            <Route component={NoMatch} />
          </Switch>
        </BaseLayout>
        <Route component={NoMatch} />
      </Switch>
    </BrowserRouter>
  )
}

export default App;
