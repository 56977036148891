import React, {useEffect, useState} from "react";


import ServiceDetail from "../../../components/ServiceDetail";
import request from "../../../utils/request";
import Configuration from './Configuration'

const AlreadyPurchased = () => {
    const [service, setService] = useState({})
    useEffect(()=> {
        request('/already-purchased/detail',{params: {route_path: 'file-management'}}).then(res => {
            if(res.code === 200){
                setService(res.data)
            }
        })
    }, [])
    return (
        <div>
            <ServiceDetail service={service}/>
            <Configuration token={service.token}/>
        </div>
    );
};

export default AlreadyPurchased;