import React, {useEffect, useState} from 'react'
import ApplicationIntroduction from '../../components/ApplicationIntroduction'
import request from '../../utils/request'
import {Skeleton} from "antd";
import AlreadyPurchased from "./components/AlreadyPurchased";

const ZohoToken = () => {
  const [isPurchase, setIsPurchase] = useState(false)
  const [spinning, setSpinning] = useState(false)
  const [application, setApplication] = useState({})
  useEffect(() => {
    setSpinning(true)
    request(`/already-purchased/payment`, { params: { route_path: 'zoho-token'}}).then(res => {
      if (res.code === 200) {
        setIsPurchase(res.data.is_payment)
      }
      if (!isPurchase) {
        request('/application/zoho-token').then(res => {
          if (res.code === 200) {
            setApplication(res.data)
          }
        })
      }
    })
    setSpinning(false)
  }, [])
  return (
      <Skeleton action loading={spinning}>
        { isPurchase ? <AlreadyPurchased /> : <ApplicationIntroduction application={application}/>}
      </Skeleton>


  )
}

export default ZohoToken;
