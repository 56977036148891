import React, {useState, useEffect} from "react";

import {
    Button,
    Typography,
    Row,
    Col,
    Form,
    Input,
    Card,
    message,
    Modal,
    Select,
    Tag,
} from "antd";

import request from "../../../utils/request";

const {Text, Paragraph} = Typography;
const {confirm} = Modal;
const {Option} = Select;

const Configuration = (props) => {
    const [visible, setVisible] = useState(false);
    const [calendars, setCalendars] = useState([]);
    const [larkConfig, setLarkConfig] = useState({});
    const [calendar, setCalendar] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectCalendar, setSelectCalendar] = useState({});

    const [larkForm] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            span: 5,
        },
        wrapperCol: {
            span: 16,
        },
    };

    useEffect(() => {
        request("/lark/settings").then((res) => {
            if (res.code === 200) {
                setLarkConfig(res.data);
            }
        });

        request("/lark/calendars").then((res) => {
            if (res.code === 200) {
                setCalendar(res.data);
            }
        });
    }, []);

    const editLarkConfig = (values) => {
        let url = " ";
        if (larkConfig && larkConfig.app_secret) {
            url = `/lark/settings/update`;
            values.id = larkConfig.id;
        } else {
            url = "/lark/settings/create";
        }
        request(url, {method: "POST", data: values}).then((res) => {
            if (res.code === 200) {
                message.success(res.message);
            } else {
                message.error(res.message);
            }
            setTimeout(() => window.location.reload(), 1000);
        });
    };

    function syncStaff() {
        confirm({
            title: "是否需要同步飞书用户至CRM?",
            content: "只需要第一次配置时点击一次即可",
            okText: "是",
            cancelText: "否",
            onOk: () => {
                request("/lark/sync/staff").then((res) => {
                    message.success("成功");
                });
            },
        });
    }

    function onSelect(_, option) {
        setSelectCalendar(option);
    }

    function submitSelect() {
        const {key, value} = selectCalendar
        request('/lark/calendars', {method: 'POST', data: {calendar_id: value, summary: key}}).then(res => {
            if (res.code === 200) {
                message.success('保存成功')
            } else {
                message.error('保存失败')
            }
            setTimeout(window.location.reload(), 1000)
        })
    }

    function initModal() {
        request("/lark/calendars/online").then((res) => {
            if (res.code === 200) {
                setCalendars(res.data);
            }
        });
        setShowModal(true);
    }

    function editConfig() {
        larkForm.setFieldsValue({...larkConfig})
        setVisible(true)
    }

    return (
        <div className="site-page-header-ghost-wrapper" style={{marginTop: 20}}>
            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col span={12}>
                    <Card title="飞书配置" style={{width: "100%"}}>
                        {larkConfig ? (
                            <div>
                                <p>App ID: {larkConfig.app_id}</p>
                                <p>App Secret: {larkConfig.app_secret}</p>
                                <p>Encrypt Key: {larkConfig.encrypt_key}</p>
                                <p>Verification Token: {larkConfig.verification_token}</p>
                                <span>
                                    <Text>事件订阅请求网址URL：
                                    <Paragraph copyable ellipsis={true}
                                               width={50}>{larkConfig.lark_subscribe_url}</Paragraph></Text>
                                </span>
                                <div style={{marginTop: 20}}>
                                    <Button type="primary" onClick={editConfig}>编辑</Button>
                                    <Button type="primary" style={{float: "right"}} onClick={syncStaff}>同步员工</Button>
                                </div>
                            </div>
                        ) : <div>
                            <Typography.Title level={4}>没有配置</Typography.Title>
                            <Button type="primary" onClick={() => setVisible(true)}>添加配置</Button>
                        </div>}


                        <Modal visible={visible} onCancel={() => setVisible(false)} width={800} title="飞书配置" okText="确定"
                               cancelText="取消" onOk={() => larkForm.submit()}>
                            <Form
                                {...formItemLayout}
                                name="飞书配置"
                                onFinish={editLarkConfig}
                                form={larkForm}
                            >
                                <Form.Item
                                    label="App ID"
                                    name="app_id"
                                    rules={[{required: true}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="App Secret"
                                    name="app_secret"
                                    rules={[{required: true}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Encrypt Key"
                                    name="encrypt_key"
                                    rules={[{required: true}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Verification Token"
                                    name="verification_token"
                                    rules={[{required: true}]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Form>

                        </Modal>
                    </Card>
                </Col>
                {larkConfig && (
                    <Col span={12}>
                    <Card title="日历配置-日历列表">
                        {calendar && calendar.summary ? (
                            <Text>
                                <Tag color="blue">{calendar.summary}</Tag>
                                <Button onClick={initModal} type="link">修改</Button>
                            </Text>
                        ) : (
                            <Button onClick={initModal} type="primary">
                                选择需要同步的日历
                            </Button>
                        )}

                        <Modal
                            visible={showModal}
                            title="日历列表"
                            okText="确定"
                            cancelText="取消"
                            onCancel={() => setShowModal(false)}
                            onOk={submitSelect}
                        >
                            <Select
                                defaultValue="请选择需要同步的日历"
                                style={{width: 200}}
                                onSelect={onSelect}
                            >
                                {calendars.map((calendar) => (
                                    <Option
                                        key={calendar.summary}
                                        value={calendar.calendar_id}
                                        disabled={
                                            !["primary", "shared"].includes(calendar.type) ||
                                            !["writer", "owner"].includes(calendar.role)
                                        }
                                    >
                                        {calendar.summary}
                                    </Option>
                                ))}
                            </Select>
                        </Modal>
                    </Card>
                </Col>
                )}
                
            </Row>
        </div>
    );
};

export default Configuration;
