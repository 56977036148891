import React, {useEffect, useState} from "react";

import ApplicationIntroduction from '../../components/ApplicationIntroduction'
import request from "../../utils/request";
import AlreadyPurchased from "./components/AlreadyPurchased";
import {Skeleton} from "antd";

const Lark = () => {
    const [isPurchase, setIsPurchase] = useState(false)
    const [spinning, setSpinning] = useState(false)
    const [application, setApplication] = useState({})
    useEffect(()=> {
        setSpinning(true)
        request(`/already-purchased/payment`, { params: { route_path: 'lark'}}).then(res => {
            if (res.code === 200) {
                setIsPurchase(res.data.is_payment)
            }
            if (!isPurchase) {
                request('/application/lark').then(res => {
                    if (res.code === 200) {
                        setApplication(res.data)
                    }
                })
            }
        })
        setSpinning(false)
    }, [])
  return (
      <Skeleton action loading={spinning}>
          {isPurchase ? (<AlreadyPurchased/>) : <ApplicationIntroduction application={application}/>}
      </Skeleton>
  )
}

export default Lark;