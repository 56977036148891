import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  message,
  Tooltip,
  Checkbox,
  Popover,
  Progress,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import RegisterResult from "./components/RegisterResult";

import UserLayout from "../layouts/UserLayout";

import request from "../../utils/request";
import styles from "./style.less";

const FormItem = Form.Item;
const passwordStatusMap = {
  ok: <div className={styles.success}>强度：强</div>,
  pass: <div className={styles.warning}>强度：中</div>,
  poor: <div className={styles.error}>强度：太短</div>,
};
const passwordProgressMap = {
  ok: "success",
  pass: "normal",
  poor: "exception",
};

const Register = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [form] = Form.useForm();
  const [visible, setvisible] = useState(false);
  const [popover, setpopover] = useState(false);
  const [isRead, setIsRead] = useState(false)

  const confirmDirty = false;

  const onFinish = (values) => {
    console.log(values);
    setButtonLoading(true);
    let params = { method: "POST", data: values };
    request("/user/register", params).then((res) => {
      if (res.code === 200) {
        setShowResult(true);
      } else {
        message.error(res.message);
        setButtonLoading(false);
      }
    });
  };

  // 检验密码长度
  const getPasswordStatus = () => {
    const value = form.getFieldValue("password");

    if (value && value.length > 9) {
      return "ok";
    }

    if (value && value.length > 5) {
      return "pass";
    }

    return "poor";
  };

  const renderPasswordProgress = () => {
    const value = form.getFieldValue("password");
    const passwordStatus = getPasswordStatus();
    return value && value.length ? (
      <div className={styles[`progress-${passwordStatus}`]}>
        <Progress
          status={passwordProgressMap[passwordStatus]}
          className={styles.progress}
          strokeWidth={6}
          percent={value.length * 10 > 100 ? 100 : value.length * 10}
          showInfo={false}
        />
      </div>
    ) : null;
  };

  // 密码校验
  const checkPassword = (_, value) => {
    const promise = Promise; // 没有值的情况

    if (!value) {
      setvisible(!!value);
      return promise.reject("请输入密码！");
    } // 有值的情况

    if (!visible) {
      setvisible(!!value);
    }

    setpopover(!popover);

    if (value.length < 6) {
      return promise.reject("");
    }

    if (value && confirmDirty) {
      form.validateFields(["confirm"]);
    }

    return promise.resolve();
  };

  // 检验确认密码
  const checkConfirm = (_, value) => {
    const promise = Promise;

    if (value && value !== form.getFieldValue("password")) {
      return promise.reject("两次输入的密码不匹配!");
    }

    return promise.resolve();
  };

  return (
    <UserLayout>
      {showResult ? (
        <RegisterResult
          email={form.getFieldValue("email")}
          phone_number={form.getFieldValue("phone_number")}
        />
      ) : (
        <div className="main">
          <h3>注册</h3>
          <Form form={form} name="UserRegister" onFinish={onFinish}>
            <FormItem
              name="name"
              rules={[{ required: true, message: "请输入您的姓名" }]}
            >
              <Input
                size="large"
                suffix={
                  <Tooltip title="你希望别人怎么称呼你?">
                    <QuestionCircleOutlined />
                  </Tooltip>
                }
                placeholder="昵称"
              />
            </FormItem>
            <FormItem
              name="email"
              rules={[
                { required: true, message: "请输入您的邮箱" },
                {
                  type: "email",
                  message: "请输入正确的邮箱格式",
                },
              ]}
            >
              <Input size="large" placeholder="邮箱" />
            </FormItem>
            <FormItem
              name="phone_number"
              rules={[
                { required: true, message: "请输入您的手机号码" },
                {
                  pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                  message: "请输入正确手机号码",
                },
              ]}
            >
              <Input size="large" placeholder="手机号" />
            </FormItem>
            <Popover
              getPopupContainer={(node) => {
                if (node && node.parentNode) {
                  return node.parentNode;
                }

                return node;
              }}
              content={
                visible && (
                  <div
                    style={{
                      padding: "4px 0",
                    }}
                  >
                    {passwordStatusMap[getPasswordStatus()]}
                    {renderPasswordProgress()}
                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      请至少输入 6 个字符。请不要使用容易被猜到的密码。
                    </div>
                  </div>
                )
              }
              overlayStyle={{
                width: 240,
              }}
              placement="right"
              visible={visible}
            >
              <FormItem
                name="password"
                className={
                  form.getFieldValue("password") &&
                  form.getFieldValue("password").length > 0 &&
                  styles.password
                }
                rules={[
                  {
                    validator: checkPassword,
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="至少六位密码，区分大小写"
                />
              </FormItem>
            </Popover>
            <FormItem
              name="confirm"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "请输入密码！",
                },
                {
                  validator: checkConfirm,
                },
              ]}
            >
              <Input.Password size="large" placeholder="确认密码" />
            </FormItem>
            <FormItem
              name="corporate_name"
              rules={[{ required: true, message: "请输入您公司名称" }]}
            >
              <Input size="large" placeholder="公司名称" />
            </FormItem>
            <FormItem
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject("应阅读使用条款"),
                },
              ]}
            >
              <Checkbox onChange={() => setIsRead(!isRead)}>
                我已阅读{" "}
                <a href="/service-term" target="_blank">
                  Connector使用条款
                </a>
              </Checkbox>
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                className={styles.submit}
                disabled={!isRead}
              >
                注册
              </Button>
              <Link className="login" to="/login">
                使用已有账户登录
              </Link>
            </FormItem>
          </Form>
        </div>
      )}
    </UserLayout>
  );
};

export default Register;
