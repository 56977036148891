import React, { useEffect, useState } from "react";
import request from "../../utils/request";
import { Button, Divider, Input, Table, Modal, message, Tag } from "antd";
import moment from "moment";
import { statusTag } from "../../utils/utils";
import { useHistory } from "react-router-dom";

const { Search } = Input;
const { confirm } = Modal;

const OrderCenter = () => {
  const [orders, setOrders] = useState([]);
  const [applications, setApplications] = useState([]);
  const [info, setInfo] = useState({});
  const [condition, setCondition] = useState({});

  const history = useHistory();

  useEffect(() => {
    request("/order").then((res) => {
      if (res.code === 200) {
        setOrders(res.data.orders);
        setInfo(res.data.info);
      }

      request("/application").then((res) => {
        setApplications(res.data);
      });
    });
  }, []);

  function search(keyWord) {
    if (typeof keyWord !== "undefined" && keyWord !== null) {
      condition[
        "order_no_or_order_time_or_order_payment_time_or_app_name_or_pay_no_cont"
      ] = keyWord;
    }
    request("/order", { params: { q: condition } }).then((res) => {
      if (res.code === 200) {
        setOrders(res.data.orders);
      }
    });
  }

  // 支付订单
  function payOrder(row) {
    history.push({
      pathname: "/pay",
      state: { order: row },
    });
  }

  function cancelOrder(row) {
    confirm({
      title: "是否要取消订单？",
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        request(`/order/${row.id}`, { method: "PUT" }).then((res) => {
          if (res.code === 200) {
            message.success("取消成功", 1);
          } else {
            message.error("取消失败", 1);
          }
          setTimeout(() => window.location.reload(), 1000);
        });
      },
    });
  }

  // 删除订单
  function deleteOrder(row) {
    confirm({
      title: "是否要删除订单？",
      content: "订单删除后不可恢复",
      okText: "删除",
      cancelText: "取消",
      onOk: () => {
        request(`/order/${row.id}`, { method: "DELETE" }).then((res) => {
          if (res.code === 200) {
            message.success("删除成功", 1);
          } else {
            message.error("删除失败", 1);
          }
          setTimeout(() => window.location.reload(), 1000);
        });
      },
    });
  }

  const columns = [
    {
      title: "订单号",
      dataIndex: "order_no",
      key: "order_no",
      width: 250,
      fixed: "left",
    },
    {
      title: "应用名",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "交易号",
      dataIndex: "pay_no",
      key: "pay_no",
      width: 250,
    },
    {
      title: "账单时间",
      dataIndex: "order_time",
      key: "order_time",
      width: 200,
      sorter: (a, b) =>
        moment(a.order_time).unix() - moment(b.order_time).unix(),
      render: (order_time) => {
        return <span>{moment(order_time).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
    {
      title: "付款时间",
      dataIndex: "order_payment_time",
      key: "order_payment_time",
      width: 200,
      sorter: (a, b) =>
        moment(a.order_payment_time).unix() -
        moment(b.order_payment_time).unix(),
      render: (order_payment_time) => {
        let time = moment(order_payment_time).format("YYYY-MM-DD HH:mm:ss");
        return order_payment_time ? <span>{time}</span> : null;
      },
    },
    {
      title: "总计",
      dataIndex: "price",
      key: "price",
      width: 200,
      render: (price) => <Tag color="magenta">&yen;{price}RMB</Tag>,
    },
    {
        title: "状态",
        dataIndex: 'status',
        key: 'status',
        width: 150,
        render: (text) => statusTag(text)
    },
    {
      title: "支付方式",
      dataIndex: "payment_method",
      width: 100,
      key: "payment_method",
    },

    {
      title: "操作",
      align: "center",
      fixed: "right",
      width: 150,
      render: (row) => {
        return (
          <span>
            {row.status === "WAIT_BUYER_PAY" && (
              <>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => payOrder(row)}
                >
                  付款
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => cancelOrder(row)}
                >
                  取消订单
                </Button>
                <Divider type="vertical" />
              </>
            )}

            {row.status === "TRADE_SUCCESS" && (
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={this.drawBill}
              >
                开发票
              </Button>
            )}

            {(row.status === "BILLING_PROGRESS" ||
              row.status === "BILLING_COMPLETED") && (
              <Button
                type="link"
                style={{ padding: 0 }}
                disabled={row.status === "BILLING_PROGRESS"}
                onClick={() => this.watchInvoice(row)}
              >
                查看发票
              </Button>
            )}

            {row.status === "TRADE_CLOSED" && (
              <Button
                type="link"
                style={{ padding: 0 }}
                danger
                onClick={() => deleteOrder(row)}
              >
                删除
              </Button>
            )}
          </span>
        );
      },
    },
  ];
  return (
    <div>
      <span style={{ marginRight: 20 }}>共有 {info.total_count} 项</span>
      {applications.map((application) => (
        <Button
          key={application.name}
          type="link"
          onClick={() => search(application.name)}
        >
          {application.name}
        </Button>
      ))}
      <Search
        placeholder="请输入关键字"
        style={{
          width: "30%",
          marginBottom: 10,
          marginLeft: 10,
          float: "right",
        }}
        enterButton
        allowClear
        onSearch={search}
      />

      <Table
        rowKey="id"
        columns={columns}
        dataSource={orders}
        scroll={{ x: 1500, y: 300 }}
        pagination={{
          defaultCurrent: 1,
          total: info.total_count,
        }}
      />
    </div>
  );
};

export default OrderCenter;
