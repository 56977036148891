import { Button, Modal, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import request from "../../../utils/request";

const Configuration = () => {
  const [visible, setVisible] = useState(false);
  const [setting, setSetting] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    request("/yunzhijia/settings").then((res) => {
      if (res.code === 200) {
        setSetting(res.data);
      }
    });
  }, []);

  function openModal(params) {
    form.setFieldsValue(setting);
    setVisible(true);
  }

  function onFinish(values) {
    request("/yunzhijia/settings", { method: "POST", data: values }).then(
      (res) => {
        console.log(res);
      }
    );
  }

  return (
    <div className="site-page-header-ghost-wrapper" style={{ marginTop: 20 }}>
      配置页面
      <Modal
        width={860}
        visible={visible}
        title="云之家配置"
        onCancel={() => setVisible(false)}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="企业号码" rules={[{ required: true }]} name="no">
            <Input />
          </Form.Item>
          <Form.Item label="轻应用id" rules={[{ required: true }]} name="appId">
            <Input />
          </Form.Item>
          <Form.Item
            label="轻应用secret"
            rules={[{ required: true }]}
            name="appSecret"
          >
            <Input />
          </Form.Item>
          <Form.Item label="公众号ID" rules={[{ required: true }]} name="pub">
            <Input />
          </Form.Item>
          <Form.Item
            label="公众号SECRET"
            rules={[{ required: true }]}
            name="pubSecret"
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={openModal}>配置</Button>
    </div>
  );
};

export default Configuration;
