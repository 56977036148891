import React, { useState, useEffect } from "react";
import { Form, Input, Button, Radio, Tooltip, message } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import request from "../../../utils/request";

const InvoiceSetting = ({invoiceInfo}) => {
    const [infoType,setInfoType] = useState('personal');
    const [form] = Form.useForm();
    const initialValues = {
        name: "个人",
        issuing_type: "personal",
        invoice_type: "vatOrdinaryInvoice",
    };
    useEffect(() => {
        // console.log(invoiceInfo)
        if (invoiceInfo) {
            setInfoType(invoiceInfo.issuing_type);
            form.setFieldsValue({ ...invoiceInfo });
        } else {
            setInfoType("personal");
            form.setFieldsValue({ ...initialValues });
        }
    }, []);

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 6, span: 16 },
    };

    const selectedType = (e) => {
        setInfoType(e.target.value);
        if (e.target.value === "personal") {
            form.setFieldsValue({
                name: "个人",
                invoice_type: "vatOrdinaryInvoice",
                issuing_type: "personal",
            });
        } else if (e.target.value === "institution") {
            form.resetFields()
            form.setFieldsValue({
                invoice_type: "vatOrdinaryInvoice",
            });
        } else {
            form.resetFields()
            form.setFieldsValue({ issuing_type: "enterprise" });
        }
    };

    const onFinish = (values) => {
        request("/invoice/info", { method: "POST", data: values }).then((res) => {
            if (res.code === 200) {
                message.success("修改成功");
            } else {
                message.error(res.message);
            }
            setTimeout(() => window.location.reload(), 1500);
        });
    };

    return (
        <Form {...layout} onFinish={onFinish} form={form}>
            {infoType === "personal" && (
                <>
                    <Form.Item label="发票抬头" name="name">
                        <Input bordered={false} readOnly={true} />
                    </Form.Item>
                    <Form.Item
                        label="开具类型"
                        name="issuing_type"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group onChange={selectedType}>
                            <Radio value="personal">个人</Radio>
                            <Radio value="enterprise">企业</Radio>
                            <Radio value="institution">事业单位</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="发票类型"
                        name="invoice_type"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group>
                            <Radio value="vatOrdinaryInvoice">
                                增值税普通发票{" "}
                                <Tooltip title="增值税普通发票开给小规模纳税人或者开票资料不齐全的购买人,购买人取得后不可以进行进项税额抵扣。若您还有疑问,建议联系贵司财务确认后再提交开票需求。">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </>
            )}

            {infoType === "enterprise" && (
                <>
                    <Form.Item
                        label="开具类型"
                        name="issuing_type"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group onChange={selectedType}>
                            <Radio value="personal">个人</Radio>
                            <Radio value="enterprise">企业</Radio>
                            <Radio value="institution">事业单位</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="发票抬头" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="发票类型"
                        name="invoice_type"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group>
                            <Radio value="vatOrdinaryInvoice">
                                增值税普通发票{" "}
                                <Tooltip title="增值税普通发票开给小规模纳税人或者开票资料不齐全的购买人,购买人取得后不可以进行进项税额抵扣。若您还有疑问,建议联系贵司财务确认后再提交开票需求。">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Radio>
                            <Radio value="vatSpecialInvoice">
                                增值税专用发票{" "}
                                <Tooltip title="增值税专用发票开给一般纳税人,申请时需要提供公 司名称、税号、地址电话、开户行名称及账号,一般 纳税人批复,购买人取得后可以进行进项税额抵扣。 增值税专用发票只能开具纸质发票。若您还有疑问, 建议联系贵司财务确认后再提交开票需求。">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="统一社会信用代码"
                        name="tax_number"
                        rules={[{ required: true }]}
                        extra={
                            <div className="lhXRno">
                                请与贵公司财务人员核实后，仔细填写准确的三证合一后的社会统一信用代码，否则将影响后续发票的正常使用
                            </div>
                        }
                    >
                        <Input
                            style={{ width: "60%" }}
                            suffix={
                                <Tooltip
                                    title="根据国税发16号文件规定,自2017.7.1日,购买方为企业的,案取增值税普通发票时,应向销售方提供纳税人识别号或统一社会信用代码(简称税号)否则将不得作为税收凭证。
                      公告所称企业,包括公司、非公司制企业法人、企业分支机构、个人独資企业、合伙企业和其他企业 注:①如您不属于以上“企业”,如无税号信息,可以 不用填写。
                      ②如您属于以上“企业”,税号只能是15、17、18 20位数字或字母和数字的组合,请您填写准确"
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            }
                        />
                    </Form.Item>

                    <Form.Item label="开户银行名称" name="bank_of_deposit">
                        <Input />
                    </Form.Item>
                    <Form.Item label="基本开户账号" name="bank_account">
                        <Input />
                    </Form.Item>
                    <Form.Item label="注册场所地址" name="company_address">
                        <Input />
                    </Form.Item>
                    <Form.Item label="注册固定电话" name="work_phone">
                        <Input />
                    </Form.Item>
                </>
            )}

            {infoType === "institution" && (
                <>
                    <Form.Item
                        label="开具类型"
                        name="issuing_type"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group onChange={selectedType}>
                            <Radio value="personal">个人</Radio>
                            <Radio value="enterprise">企业</Radio>
                            <Radio value="institution">事业单位</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="发票抬头" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="发票类型"
                        name="invoice_type"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group>
                            <Radio value="vatOrdinaryInvoice">
                                增值税普通发票{" "}
                                <Tooltip title="增值税普通发票开给小规模纳税人或者开票资料不齐全的购买人,购买人取得后不可以进行进项税额抵扣。若您还有疑问,建议联系贵司财务确认后再提交开票需求。">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Radio>
                            <Radio value="vatSpecialInvoice" disabled={true}>
                                增值税专用发票{" "}
                                <Tooltip title="增值税专用发票开给一般纳税人,申请时需要提供公 司名称、税号、地址电话、开户行名称及账号,一般 纳税人批复,购买人取得后可以进行进项税额抵扣。 增值税专用发票只能开具纸质发票。若您还有疑问, 建议联系贵司财务确认后再提交开票需求。">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="统一社会信用代码"
                        name="tax_number"
                        extra={
                            <div className="lhXRno">
                                请与贵公司财务人员核实后，仔细填写准确的三证合一后的社会统一信用代码，否则将影响后续发票的正常使用
                            </div>
                        }
                    >
                        <Input
                            style={{ width: "60%" }}
                            suffix={
                                <Tooltip
                                    title="根据国税发16号文件规定,自2017.7.1日,购买方为企业的,索取增值税普通发票时,应向销售方提供纳税人识别号或统一社会信用代码(简称税号)否则将不得作为税收凭证。
                        公告所称企业,包括公司、非公司制企业法人、企业分支机构、个人独資企业、合伙企业和其他企业 注:①如您不属于以上“企业”,如无税号信息,可以 不用填写。
                        ②如您属于以上“企业”,税号只能是15、17、18 20位数字或字母和数字的组合,请您填写准确"
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item label="开户银行名称" name="bank_of_deposit">
                        <Input />
                    </Form.Item>
                    <Form.Item label="基本开户账号" name="bank_account">
                        <Input />
                    </Form.Item>
                    <Form.Item label="注册场所地址" name="company_address">
                        <Input />
                    </Form.Item>
                    <Form.Item label="注册固定电话" name="work_phone">
                        <Input />
                    </Form.Item>
                </>
            )}
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" style={{ width: "18%" }}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    );
};

export default InvoiceSetting;
