import React, { useState, useEffect } from "react";
import {
  Tabs,
  Button,
  Table,
  Form,
  Input,
  Modal,
  Radio,
  Cascader,
  message,
} from "antd";
import moment from "moment";

import "./index.css";
import InvoiceSetting from "./components/InvoiceSetting";
import InvoiceShow from "./components/InvoiceShow";
import request from "../../utils/request";
import city from "../../utils/city";

const { TabPane } = Tabs;

const InvoiceManagement = () => {
  const [edit, setEdit] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [invoice, setInvoice] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState();
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [key, setKey] = useState();
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };

  const priceTotal = (items) => {
    console.log(items);
    if (items.length === 0) {
      return;
    }
    let price = 0;
    items.forEach((item) => {
      dataSource.forEach((data) => {
        if (data.id === item) {
          price += parseFloat(data.price);
        }
      });
    });
    setTotalPrice(Math.fround(price * 100)/100);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      priceTotal(selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const showInvoice = (row) => {
    window.open(row.url);
  };

  const columns_2 = [
    {
      title: "应用名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "订单号",
      dataIndex: "order_no",
      key: "order_no",
    },
    {
      title: "交易号",
      dataIndex: "pay_no",
      key: "pay_no",
    },
    {
      title: "订单金额",
      dataIndex: "price",
      key: "key",
    },
    {
      title: "订单时间",
      dataIndex: "order_time",
      key: "order_time",
      sorter: (a, b) => moment(a.order_time).unix() - moment(b.order_time).unix(),
      render: (text) => {
        return <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
    {
      title: "支付时间",
      dataIndex: "order_payment_time",
      key: "order_payment_time",
      sorter: (a, b) => moment(a.order_payment_time).unix() - moment(b.order_payment_time).unix(),
      render: (text) => {
        return <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
  ];

  const columns_3 = [
    {
      title: "发票抬头",
      width: 100,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "统一社会信用代码",
      dataIndex: "tax_number",
      key: "1",
      width: 300,
    },
    {
      title: "发票开具方式",
      dataIndex: "invoice_method",
      key: "2",
      width: 300,
    },
    {
      title: "发票种类",
      dataIndex: "invoice_type",
      key: "3",
      width: 300,
      render: (text) => {
        if (text === "vatOrdinaryInvoice") {
          return <span>增值税普通发票</span>;
        } else {
          return <span>增值税专用发票</span>;
        }
      },
    },
    {
      title: "邮寄地址",
      dataIndex: "address",
      key: "4",
      width: 350,
    },
    {
      title: "发票状态",
      dataIndex: "status",
      key: "5",
      width: 300,
    },
    { title: "发票金额", dataIndex: "price", key: "6", width: 300 },
    {
      title: "开票时间",
      dataIndex: "created_at",
      key: "8",
      width: 300,
      render: (text) => {
        return moment(text).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "操作",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, row) => (
        <Button
          type="link"
          onClick={() => showInvoice(row)}
          disabled={row.status === "开票中"}
        >
          查看
        </Button>
      ),
    },
  ];

  useEffect(() => {
    request("/invoice/info").then((res) => {
      if (res.code === 200 && res.data) {
        setInvoiceInfo(res.data);
        setEdit(true);
      }
    });
  }, []);

  const onChange = (key) => {
    setKey(key);
    if (key === "2") {
      request("/invoice/order", {}).then((res) => {
        if (res.code === 200) {
          setDataSource(res.data);
        }
      });
    }
    if (key === "3") {
      request("/invoice/apply").then((res) => {
        setInvoice(res.data);
      });
    }
  };

  const createInvoice = (values) => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
    if (values.invoice_type === undefined) {
      values.invoice_type = invoiceInfo.invoice_type;
      values.ids = selectedRowKeys;
      values.price = totalPrice;
    }
    request("/invoice/apply", { method: "POST", data: values }).then((res) => {
      if (res.code === 200) {
        message.success("开票成功!");
      } else {
        message.error("开票申请失败");
      }
      setTimeout(() => window.location.reload(), 1500);
    });
  };

  const onChangeCity = (value, options) => {
    console.log(value, options);
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Tabs defaultActiveKey="1" onChange={onChange}>
        <TabPane tab="发票信息" key="1">
          {edit ? (
            <div className="cJEKJg">
              发票信息有效
              <Button
                size="small"
                className="cJEss"
                onClick={()=> setEdit(false)}
                type="link"
              >
                修改发票信息
              </Button>
            </div>
          ) :  !invoiceInfo ?
            <div className="cJEKJg">您的发票信息未完善，请先完善</div> : null
          }

          {edit ? (
            <InvoiceShow invoiceInfo={invoiceInfo} />
          ) : (
            <InvoiceSetting invoiceInfo={invoiceInfo} />
          )}

        </TabPane>
        <TabPane tab="开具发票" key="2">
          <div style={{ marginBottom: 16 }}>
            <Button
              loading={loading}
              type="primary"
              onClick={() => setVisible(true)}
              disabled={
                invoiceInfo.invoice_type === undefined ||
                (selectedRowKeys.length > 0 ? false : true)
              }
            >
              开具发票
            </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected
                ? `${selectedRowKeys.length}个订单，共${totalPrice}元`
                : ""}
            </span>
            {invoiceInfo.issuing_type === undefined ? (
              <div className="cJEKJg">您的发票信息未完善，请先完善</div>
            ) : null}
          </div>
          <Table
            columns={columns_2}
            dataSource={dataSource}
            rowKey="id"
            rowSelection={rowSelection}
          />
        </TabPane>
        <TabPane tab="我的发票" key="3">
          <Table
            rowKey="id"
            columns={columns_3}
            dataSource={invoice}
            scroll={{ x: 1500, y: 300 }}
          />
        </TabPane>
      </Tabs>

      {key === "2" ? (
        <Modal
          visible={visible}
          title="开具发票"
          onOk={() => form.current.submit()}
          onCancel={() => setVisible(false)}
          okText="确认"
          cancelText="取消"
        >
          <Form {...layout} onFinish={createInvoice} ref={form}>
            <Form.Item label="发票类型" name="invoice_type">
              <span>
                {invoiceInfo.invoice_type === "vatOrdinaryInvoice"
                  ? "增值税普通发票"
                  : "增值税普通发票"}
              </span>
            </Form.Item>
            <Form.Item
              label="开票方式"
              name="invoice_method"
              rules={[{ required: true }]}
            >
              <Radio.Group onChange={(e) => setType(e.target.value)}>
                <Radio value={0}>电子发票</Radio>
                <Radio value={1}>纸质发票</Radio>
              </Radio.Group>
            </Form.Item>
            {type === 0 ? (
              <Form.Item
                label="邮箱"
                name="email"
                rules={[
                  { required: true, message: "邮箱不能为空" },
                  {
                    type: "email",
                    message: "请输入正确的邮箱格式",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : type === 1 ? (
              <>
                <Form.Item
                  label="邮寄地址"
                  name="city"
                  rules={[{ required: true, message: "邮寄地址不能为空" }]}
                >
                  <Cascader
                    options={city}
                    onChange={() => onChangeCity}
                    placeholder="请选择邮寄地址"
                  />
                </Form.Item>
                <Form.Item
                  label="详细地址"
                  name="address"
                  rules={[{ required: true, message: "邮寄地址不能为空" }]}
                >
                  <Input />
                </Form.Item>
              </>
            ) : null}
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

export default InvoiceManagement;
