import React, {useEffect, useState} from 'react';
import request from "../../utils/request";
import AlreadyPurchased from "./components/AlreadyPurchased";
import ApplicationIntroduction from "../../components/ApplicationIntroduction";
import {Skeleton} from "antd";

const WechatAccount = (props) => {
    const [isPurchase, setIsPurchase] = useState(false)
    const [spinning, setSpinning] = useState(false)
    const [application, setApplication] = useState({})
    const [routePath, setRoutePath] = useState()
    useEffect(() => {
        const path = props.location.pathname.split("/");
        const routePath = path.pop();
        setRoutePath(routePath)
        setSpinning(true)
        request(`/already-purchased/payment`, { params: { route_path: routePath}}).then(res => {
            if (res.code === 200) {
                setIsPurchase(res.data.is_payment)
            }
            if (!isPurchase) {
                request(`/application/${routePath}`).then(res => {
                    if (res.code === 200) {
                        setApplication(res.data)
                    }
                })
            }
        })
        setSpinning(false)
    }, [])
    return (
            <Skeleton action loading={spinning}>
                { isPurchase ? <AlreadyPurchased routePath={routePath} /> : <ApplicationIntroduction application={application}/>}
            </Skeleton>
    );
};

export default WechatAccount;