import React, { useEffect,useState }from 'react'

import {
  Table,
  Dropdown,
  Menu,
  Button,
  Badge,
  Input,
  Modal,
  message,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import moment from "moment"

import request from "../../utils/request"

const columns = [
  {
    title: "内容",
    dataIndex: "content",
    key: "content",
    render: (text, row) => {
      if (row.is_read === "未读") return <Badge dot>{text}</Badge>;
      else return <span>{text}</span>;
    },
  },
  {
    title: "所属应用",
    dataIndex: "app_name",
    key: "app_name",
  },
  {
    title: "日志时间",
    dataIndex: "log_time",
    key: "log_time",
    render: (log_time) => {
      let time = moment(log_time).format("YYYY-MM-DD HH:mm:ss");
      return <span>{time}</span>;
    },
  },
];

const {Search} = Input
const {confirm} = Modal
  
 

const SystemLog = () => {

  const [logs, setLogs] = useState([])
  const [info,setInfo] = useState({})
  const [condition,setCondition] = useState({})
  const [selectedRowKeys,setSelectedRowKeys] = useState([]);
  const [loading,setLoading] = useState(true)
  

  useEffect(() =>{
    request('/log',{params:{page:1, per_page:10}}).then(res=>{
      setLogs(res.data)
      setInfo(res.info)
      setLoading(false)
    })
  },[])

  const handleMenuClick = e =>{
     if (e.key === "delete") {
       deleteLogs();
     } else if (e.key === "read_all") {
       haveRead();
     }
  }

  const deleteLogs = () => {
    confirm({
      title: "是否确定删除勾选的日志信息？",
      content: "删除后不可恢复！",
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        return new Promise((resolve) => {
          request('/log/delete',{method: 'DELETE',data:{ids: selectedRowKeys}}).then(res =>{
            setSelectedRowKeys([]);
            if(res.code === 200 ){
              message.success(res.message)
              setTimeout(() => window.location.reload(), 1000);
            }
          })
        })
      }
    });
    
    
  }

  const haveRead = () => {
    confirm({
      title: "是否确定已读勾选的日志信息？",
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        return new Promise((resolve) => {
          request("/log/read", {
            method: "PUT",
            data: { ids: selectedRowKeys },
          }).then((res) => {
            setSelectedRowKeys([]);
            if (res.code === 200) {
              message.success(res.message);
              setTimeout(() => window.location.reload(), 1000);
            }
          });
        });
      },
    });

  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="delete">批量删除</Menu.Item>
      <Menu.Item key="read_all">批量设为已读</Menu.Item>
    </Menu>
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      console.log('selectedRowKeys',selectedRowKeys);
     setSelectedRowKeys(selectedRowKeys)
    },
  };

  const jumpPage = (page, pageSize) => {
    setLoading(true);
    request("/log", {
      params: { page: page, per_page: pageSize, ...{ q: condition } },
    }).then((res) => {
      if (res.code === 200) {
        setLogs(res.data);
        setInfo(res.info);
        setLoading(false);
      }
    });    

  }

  const pageSizeChange = (current, size ) => {
    setLoading(true)
    request("/log", {
      params: { page: current, per_page: size, ...{ q: condition } },
    }).then((res) => {
      if (res.code === 200) {
        setLogs(res.data);
        setInfo(res.info);
        setLoading(false);
      }
    });
  }

  const onSearch = (keyWord) => {
    console.log('keyWord',keyWord);
    condition["content_or_app_name_i_cont"] = keyWord;
    setLoading(true);
    setCondition(condition)
    request("/log", {
      params: { page: 1, per_page: 10, ...{ q: condition } }
    }).then((res) => {
      if (res.code === 200) {
        setLogs(res.data);
        setInfo(res.info);
        setLoading(false);
      }
    });
  }

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Dropdown
          overlay={menu}
          disabled={selectedRowKeys.length > 0 ? false : true}
        >
          <Button type="primary">
            批量操作 <DownOutlined />
          </Button>
        </Dropdown>
        <Search
          placeholder="input search text"
          style={{ width: "30%", float: "right" }}
          enterButton
          allowClear
          onSearch={onSearch}
        />
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={logs}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{
            current: info.page || 1,
            total: info.total_count,
            onChange: (page,size) => jumpPage(page, size),
            onShowSizeChange: (current, size) => pageSizeChange(current, size),
            showTotal: () => <span>共有{info.total_count}条</span>,
        }}
      />
    </>
  );
}

export default SystemLog
