import React from "react";

import { Typography } from "antd";

const { Paragraph, Text } = Typography;

const Configuration = ({token}) => {
  return (
    <div className="site-page-header-ghost-wrapper" style={{ marginTop: 20 }}>
      <Paragraph>
        <blockquote>
            <Text strong>系统访问凭证：</Text>
            <Paragraph copyable>{token}</Paragraph></blockquote>
      </Paragraph>
    </div>
  );
};

export default Configuration;
