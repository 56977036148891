import React from "react";
import { Result, Button, Statistic } from "antd";
const SuccessPage = (props) => {
    const { order, back } = props;
    const { Countdown } = Statistic;

    const deadline = Date.now() + 1000 * 5;

    const detail = (
        <p>
            订单编号:${order.order_no}，
            <Countdown
                value={deadline}
                onFinish={back}
                format="s 秒"
                style={{ display: "inline-block" }}
                valueStyle={{ fontSize: "15px", color: "#ff0000" }}
            />
            后将自动跳转页面！
        </p>
    );

    return (
        <Result
            status="success"
            title="付款成功"
            subTitle={detail}
            extra={[
                <Button type="primary" key="console" onClick={back}>
                    立即跳转
                </Button>,
            ]}
        />
    );
};

export default SuccessPage;
