import React, {useEffect, useState} from 'react'
import ApplicationIntroduction from '../../components/ApplicationIntroduction'
import AlreadyPurchased from "./components/AlreadyPurchased";
import request from '../../utils/request'
// import './index.css'
import { Skeleton } from "antd";

const Wecom = () => {
    const [isPurchase, setIsPurchase] = useState(false)
    const [spinning, setSpinning] = useState(false)
    const [application, setApplication] = useState({})
    useEffect(() => {
        setSpinning(true)
        request(`/already-purchased/payment`, {params: {route_path: 'wecom' }}).then(res => {
            if (res.code === 200) {
                setIsPurchase(res.data.is_payment)
            }
            if (!isPurchase) {
                request('/application/wecom').then(res => {
                    if (res.code === 200) {
                        setApplication(res.data)
                    }
                })
            }
        })
        setSpinning(false)
    }, [])
    return (
        <Skeleton action loading={spinning}>
            {isPurchase ? (<AlreadyPurchased/>) : <ApplicationIntroduction application={application}/>}
        </Skeleton>


    )
}

export default Wecom
