import React from 'react';
import logo from '../../assets/logo.svg';
import './UserLayout.less';

const helpDetail = (
  <div>
    <div>售前电话：400-998-6877</div>
    <div>售前联系：sales@netfarmer.com.cn</div>
  </div>
);

const UserLayout = ({ children }) => {
  return (
    <div className='container'>
      <div className='content'>
        <div className='top'>
          <div className='header'>
            <a href='https://connector.netfarmer.com.cn/'>
              <div style={{ marginBottom: 16 }}>
                <img alt='logo' className='logo' src={logo} />
                <div style={{ display: 'inline-block', marginTop: 10 }} className='home-title'>
                  Connector
                </div>
              </div>
            </a>
          </div>
          <div className='desc'>Connector 是一款专注于实现各种产品对接的产品</div>
        </div>
        {children}
      </div>
      <span className='copyright'>
        {helpDetail}
        {`Connector System ©2021-${new Date().getFullYear()} Created by 上海旺田信息科技有限公司`}
      </span>
    </div>
  );
};

export default UserLayout;
