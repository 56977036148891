import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    Divider,
    Form,
    Radio,
    Input,
    message,
    Modal,
    TimePicker,
    InputNumber,
    Typography
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import request from "../../../utils/request";

const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 14 },
};

const { Link } = Typography;

const { confirm } = Modal;
const format = "HH:mm";

const Configuration = ({type}) => {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [rowId, setRowId] = useState(null)
    const [configs, setConfigs] = useState([]);
    const [visible, setVisible] = useState(false);
    const [settingVisible, setSettingVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const columns = [
        {
            title: "appID",
            key: "appID",
            dataIndex: "appid",
        },
        {
            title: "appSecret",
            key: "secret",
            dataIndex: "secret",
        },
        {
            title: '同步时间',
            key: 'sync_time',
            dataIndex: "sync_time",
        },
        {
            title: '同步间隔时间(小时)',
            key: 'sync_interval',
            dataIndex: "sync_interval",
        },
        {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 200,
            render: (text, row, index) => {
                return (
                    <span>
            <Button
                size="small"
                style={{ padding: 0 }}
                type="link"
                onClick={() => deleteRow(row)}
                danger
            >
              删除
            </Button>
            <Divider type="vertical" />
            <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => settingRow(row)}
            >
              同步设置
            </Button>
            <Divider type="vertical" />
            <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => syncRow(row)}
            >
              同步
            </Button>
          </span>
                );
            },
        },
    ];

    useEffect(() => {
        request(`/wechat/settings?wechat_type=${type}`).then((res) => {
            if (res.code === 200) {
                setConfigs(res.data);
            }
        });
    }, []);

    function deleteRow(row) {
        confirm({
            title: '是否确认删除该微信公众号配置？',
            content: '删除之后无法同步，后果自行承担',
            okText: "是",
            cancelText: "否",
            onOk: () => {
                request(`/wechat/settings/delete`,{method: "POST", data: {id: row.id}}).then(res => {
                    if(res.code === 200) {
                        message.success('删除成功')
                    }else{
                        message.error('删除失败，请稍后重试')
                    }
                    setTimeout(() => window.location.reload(), 1000)

                })
            }
        })
    }

    function settingRow(row) {
        setRowId(row.id)
        setSettingVisible(true);
        if(row.sync_time && row.sync_interval){
            form1.setFieldsValue({sync_time: moment(row.sync_time,"HH:mm"), sync_interval: row.sync_interval})
        }
    }

    function syncRow(row) {
        confirm({
            title: "是否需要同步该微信公众号？",
            cancelText: "否",
            okText: "是",
            onOk: function () {
                request(`/wechat/sync/${row.id}`, { method: "POST" }).then((res) => {
                    if(res.code === 200) {
                        message.info('同步在后台运行，请稍后查看日志')
                    }
                });
            },
        });
    }

    function onFinish(values) {
        if (!values.id) {
            delete values.id;
        }
        setConfirmLoading(true);
        request("/wechat/settings", { method: "POST", data: { ...values } }).then(
            (res) => {
                setConfirmLoading(false);
                if (res.code === 200) {
                    message.success("保存成功");
                } else {
                    message.error(res.message);
                }
                setTimeout(() => {
                    setVisible(false);
                    form.resetFields();
                }, 1000);
            }
        );
    }

    function onSettingFinish(values) {
        if (values["sync_time"]) {
            values["sync_time"] = moment(values["sync_time"]).format("HH:mm");
        }

        request(`/wechat/settings/sync/${rowId}`, { method: "POST", data: { ...values } }).then(res => {
            if(res.code === 200){
                message.success('更新成功');
            }else{
                message.error(res.message);
            }
            setTimeout(() => window.location.reload(), 1000)
        })

    }

    function onChange(value) {
        const time = moment(form1.getFieldValue("sync_time")).hours();
        if(value + time >= 24){
            message.error('下一次同步时间超过今天');
            form1.setFieldsValue({ 'sync_interval': null})
        }
    }

    return (
        <div style={{ marginTop: 20 }} className="site-page-header-ghost-wrapper">
            <Button
                type="primary"
                onClick={() => setVisible(true)}
                style={{ marginBottom: 15}}
            >
                <PlusOutlined /> {type === "wechat-subscribe" ? "微信订阅号配置" : "微信服务号配置"}
            </Button>

            <Typography style={{ display: 'inline'}}>
                {/*<Link style= {{ marginLeft: 10}} href="/docs/wechat" target="_blank">配置教程</Link>*/}
            </Typography>

            <Table columns={columns} dataSource={configs} rowKey="id" />

            <Modal
                title={type === "wechat-subscribe" ? "微信订阅号配置" : "微信服务号配置"}
                width="40%"
                confirmLoading={confirmLoading}
                onOk={() => form.submit()}
                okText="提交"
                cancelText="取消"
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                    form.resetFields();
                }}
            >
                <Form {...layout} form={form}  layout="horizontal" onFinish={onFinish}>
                    <Form.Item name="id" hidden />
                    <Form.Item
                        name="appid"
                        label="开发者ID(AppID)"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="secret"
                        label="开发者密码(AppSecret)"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="同步设置"
                confirmLoading={confirmLoading}
                visible={settingVisible}
                width="40%"
                okText="提交"
                cancelText="取消"
                onOk={() => form1.submit()}
                onCancel={() => {
                    setSettingVisible(false);
                    form1.resetFields();
                }}
            >
                <Form
                    {...layout}
                    form={form1}
                    layout="horizontal"
                    onFinish={onSettingFinish}
                >
                    <Form.Item name="id" hidden />
                    <Form.Item
                        label="同步时间"
                        name="sync_time"
                        rules={[{ required: true }]}
                    >
                        <TimePicker format={format} />
                    </Form.Item>
                    <Form.Item
                        name="sync_interval"
                        label="同步间隔时间"
                        rules={[{ required: true }]}
                    >
                        <InputNumber min={1} max={23} onChange={onChange} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Configuration;
