import React, { useEffect, useState } from "react";
import { Card, Col, Row, Statistic, Result } from "antd";

import moment from "moment";

import "./index.css";
import request from "../../utils/request";
import { timeComparison } from "../../utils/utils";

const { Countdown } = Statistic;
const Dashboard = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    request("/dashboard").then((res) => {
      if (res.code === 200) {
        setServices(res.data);
      }
      
    });
  }, []);

  return (
    <>
      {services.length === 0 && <Result title="您还未购买任何应用" />}

      {services.length > 0 && (
        <Row gutter={[24, 16]}>
          {services.map((service) => {
            return (
              <Col span={6} key={service.routeName}>
                <Card
                  title={
                    <div>
                      <span>{service.app_name}</span>
                    </div>
                  }
                  style={{ width: "100%" }}
                >
                  <Countdown
                    valueStyle={!timeComparison(service.end_time) ? {color: 'red'} : null }
                    title="剩余天数"
                    value={moment(service.end_time).format("YYYY-MM-DD")}
                    format="D 天"
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default Dashboard;
