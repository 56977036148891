import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import styles from "./style.less";

const actions = (
    <div className={styles.actions}>
        <Link to="/login">
            <Button size="large" type="primary">
                登陆
            </Button>
        </Link>
    </div>
);

const RegisterResult = (props) => {
    return (
        <Result
            className={styles.registerResult}
            status="success"
            title={
                <div className={styles.title}>
                    你的账户：{props.phone_number} 注册成功
                </div>
            }
            subTitle={`激活邮件已发送到你的邮箱${props.email}中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。`}
            extra={actions}
        />
    );
};

export default RegisterResult;
