import React, { useState, useEffect } from "react";
import { message, Radio, Statistic } from "antd";
import QRCode from "qrcode.react";
import moment from "moment";
import actionCable from "actioncable";
import request from "../../utils/request";
import SuccessPage from "./components/SuccessPage";

import "./index.css";

import wxPay from "../../assets/weixin.gif";
import aliPay from "../../assets/zhifubao.gif";

const { Countdown } = Statistic;

const PaymentPage = (props) => {
  const [showPaymentCode, setShowPaymentCode] = useState(false);
  const [codeUrl, setCodeUrl] = useState("");
  const [showResult, setShowResult] = useState(false);
  const { order } = props.location.state;
  const deadline = moment(order.order_time)
    .add(30, "m")
    .format("YYYY-MM-DD HH:mm:ss");
  useEffect(() => {
    const token = localStorage.getItem("token");
    const cableApp = actionCable.createConsumer(
      "wss://app.netfarmer.com.cn/api/cable?token=" + token
    );
    cableApp.subscriptions.create(
      {
        channel: "MessagesChannel",
      },
      {
        received: (msg) => {
          if (msg.type === "SUCCESS") {
            setShowResult(true);
          }
        },
      }
    );
    return () => {
      cableApp.disconnect();
    };
  }, []);

  const getPaymentCode = (e) => {
    console.log(e.target.value);
    const payment_way = e.target.value;
    if (payment_way === "") {
      return;
    }
    request("/pay", {
      method: "get",
      params: { id: order.id, payment_way: payment_way },
    }).then((res) => {
      if (res.code === 200) {
        setCodeUrl(res.data.pay_url);
        setShowPaymentCode(true);
      } else {
        message.error("订单异常！");
      }
    });
  };

  const refreshPage = () => {
    props.history.push({ pathname: `/application/${order.app_id}` });
  };

  return (
    <>
      {showResult ? (
        <SuccessPage order={order} back={refreshPage} />
      ) : (
        <div>
          <div className="logo">Connector 收银台</div>
          <div className="header">
            <div className="left-header">
              <div>订单编号： {order && order.order_no}</div>
              <div>订单类型：{order && order.name}</div>
            </div>
            <div className="right-header">
              <Countdown
                title="距离订单失效还有"
                value={deadline}
                format="HH:mm:ss"
                onFinish={refreshPage}
              />
            </div>
          </div>
            <div className="pay-footer">
              <div className="footer-header">
                <h3>请选择付款方式</h3>
              </div>
              <div className="payment-style">
                <Radio.Group
                  onChange={getPaymentCode}
                  style={{ paddingLeft: 50 }}
                >
                  <Radio value="ali_pay">
                    <span style={{ padding: 0, marginLeft: "-20px" }}>
                      <img src={aliPay} alt="" />
                    </span>
                  </Radio>
                  <Radio value="wx_pay">
                    <span style={{ padding: 0, marginLeft: "-10px" }}>
                      <img src={wxPay} alt="" />
                    </span>
                  </Radio>
                </Radio.Group>
              </div>
              <div className="price">
                <span>&yen; {order.price}</span>
              </div>
              {showPaymentCode ? (
                <div className="code">
                  <QRCode value={codeUrl} size={200} fgColor="#000000" />
                </div>
              ) : null}
            </div>
        </div>
      )}
    </>
  );
};

export default PaymentPage;
