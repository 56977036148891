import React, {useEffect, useState} from 'react';
import request from "../../../utils/request";
import ServiceDetail from "../../../components/ServiceDetail";
import Configuration from "./Configuration";

const AlreadyPurchased = ({routePath}) => {
    const [service, setService] = useState({})
    useEffect(()=> {
        request('/already-purchased/detail',{params: {route_path: routePath}}).then(res => {
            if(res.code === 200){
                setService(res.data)
            }
        })
    }, [])
    return (
        <div>
            <ServiceDetail service={service} />
            <Configuration type={routePath}/>
        </div>
    );
};

export default AlreadyPurchased;