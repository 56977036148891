import React from "react";
import "./index.css";

const ServiceTerm = () => {
  return (
    <div className="term">
      <h3>服务条款</h3>
      <h3>更新日期：2021年3月19日</h3>
      <h3>生效日期：2021年4月19日</h3>
      <p>
        本服务条款是您或您所代表的实体（以下称“您”或“您的”）与上海旺田信息技术有限公司(以下称“Netfarmer”)就使用Netfarmer系列SaaS达成的协议。
      </p>
      <h3>协议条款</h3>
      <p>
        本协议包含以下条款(以下称“一般条款”)和一些具体服务相关的条款（以下称“具体服务条款”），二者统称为“条款”。若二者有冲突，以具体服务条款为准。
      </p>
      <h3>条款的接受 </h3>
      <p>
        您必须达到法定年龄才能接受此条款。如您不同意本服务条款，请不要使用Netfarmer提供的任何服务。如果您同意一般条款却不同意具体服务条款，请不要使用相应的具体服务。您一旦使用Netfarmer提供的服务，或通过勾选复选框或点击按钮来接受条款，即视为您已了解并完全同意本服务条款各项内容。服务描述
        我们提供多种对接服务，包括中间库、微信对接、薪资对接、人力对接等（皆称“服务”）。您可以以个人或公司名义，通过网络浏览器来使用Netfarmer服务。您应自行负责获取因特网访问及相关设备以便使用我们的服务。您可以在您的Netfarmer帐号下创建、发布或共享给其他人。
      </p>
      <h3>Beta(测试)服务</h3>
      <p>
        我们会提供某些封闭性或公开的测试服务，用于产品的测试和评估。您同意我们有权自行确定测试和评估的周期，以及是否可以推出商业版。不会因您使用了测试期的服务而强求您必须使用付费服务，且Netfarmer在不通知您的前提下，保留随时、暂时或永久取消测试服务的权利。您同意，Netfarmer不会对因测试版服务的修改，暂停或终止而产生、引起或有关的对您或任何第三方的任何伤害而负责。
      </p>
      <h3>服务条款变更</h3>
      <p>
        我们可能会随时通过服务公告或发送邮件到您的邮箱提前通知您，并进行服务条款的修改。如果我们对服务条款进行较大修改并影响您的权利，您至少会提前30天在您的邮箱收到更改通知。如果条款变更在实质上影响了您使用服务的权利，您可以通过在被通知服务条款修改30天之内提供给Netfarmer邮件通知来终止使用服务。在这种终止情况下，您将有权获得任何预付费用的未使用部分的按比例退款。如果知道条款变更后您继续使用本服务,将被视为接受变更后的条款。
      </p>
      <h3>注册帐号</h3>
      <p>
        为了访问和使用Netfarmer服务，您需要提供所需信息，注册一个用户帐号。如果您代表一个公司，我们建议您及公司内部所有用户在注册帐号时，使用公司电子邮件地址并提供公司联系信息。
        <br />
        您同意：
        <ol>
          <li>提供真实、准确、完整、最新的个人信息；</li>
          <li>
            当个人信息变更时，及时修改，以保证其真实准确。若Netfarmer发现或有足够理由怀疑您的信息不真实、不准确、过期或不完整，我们会终止您的用户帐号，拒绝您使用部分或全部Netfarmer服务。
          </li>
        </ol>
      </p>
      <h3>机构账号和管理员</h3>
      <p>
        当您为您的机构注册账号时，您可以指定一个或多个管理员。管理员将有权根据您的需求配置服务并管理您机构账号里的用户。如果您的机构账号是由第三方代表您创建并设置，
        很可能第三方将被认定为承担您机构的管理员角色。确保您与此第三方签订了合适的协议来说明此第三方作为您机构账号管理员的角色和限制。
        <br />
        您有义务:
        <ol>
          <li>确保您机构账号密码的保密性</li>
          <li>指定专门人员作为管理员来管理您的机构账号</li>
          <li>确保与您机构账号相关的发生的所有活动符合本条款。</li>
        </ol>
        您了解Netfarmer不对您的账号管理和服务内部管理负责,您有责任采取必要措施，确保您的机构掌握管理员账号的控制权。您可以发送邮件到
        <span className="email">support@netfarmer.com.cn</span>
        。声明在管理员账号控制权丢失的情况下恢复控制权遵循的流程，前提是此流程符合Netfarmer要求。在缺少指定管理员账号恢复流程的情况下，Netfarmer可以提供管理员账号控制权给个人，前提是提供给Netfarmer充分的证据证明有代表机构执行的授权。您同意不追究在这种情况下因Netfarmer做出的任何善意操作所带来后果及Netfarmer方面的责任。
      </p>
      <h3>个人信息及隐私</h3>
      您向Netfarmer提供的个人信息受隐私政策保护。您选择使用Netfarmer服务表明您完全接受
      Netfarmer隐私政策。由您负责维护自己的用户名、密码及其它敏感信息，并对您帐号下的所有操作承担责任。如您发现帐号发生未经许可的使用，请发送邮件至：
      <span className="email">support@netfarmer.com.cn</span>
      或拨打电话：400-998-6877。由于您的用户帐号未经授权访问或使用，对您或第三方造成的损失，Netfarmer不承担任何责任。
      <h3>Netfarmer公告</h3>
      服务会包含Netfarmer发布的公告，如服务更新通知、管理消息以及企业新闻等。您了解此类公告属于Netfarmer服务的一部分。作为我们保护您隐私的一部分，您可以退订企业新闻，但无法取消接收服务更新通知和管理消息。
      投诉
      如果我们收到任何与您使用服务相关的投诉，我们将转发此投诉到您用户账号的主邮箱地址。您必须在收到我们转发投诉10天内直接回复投诉并将通信内容通知给Netfarmer。如果在我们发送邮件日期10天内您没有进行回复，我们将把您的姓名和联系信息公布给投诉人以使投诉人对您采取法律措施。您了解您在10天限制之内未能回复转发投诉将被视为您同意Netfarmer把您的姓名和联系信息公布给投诉人。
      <h3>费用和付款</h3>
      Netfarmer服务有不同产品的版本。您了解可以按月或按年的方式购买Netfarmer
      产品付费版本服务，将要到达服务期限请及时续订。从服务截至日期开始的14天内，您应进行服务续订，在此期间Netfarmer为您保持原版本的服务。超过这个缓冲期仍未续费，我们认为您将不再使用Netfarmer服务付费版本，您的服务将被自动降级为免费版。
      我们可能会不时更改现在免费服务的费用。在您当前订购期限结束之前，将不会执行任何费用的增加。除非您选择订购付费版，您将不会被收取任何服务费用。
      <h3>使用限制</h3>
      除本协议的所有其它条款及条件外，
      <br />
      您不能：
      <ol>
        <li>转移此服务或使其应用于任何第三方</li>
        <li>未经Netfarmer书面许可向第三方提供基于Netfarmer服务的服务</li>
        <li>在不同意第三方网站服务条款的前提下，使用其网站链接</li>
        <li>未经第三方书面许可而发布链接到其网站使用其Logo、公司名称等信息</li>
        <li>未经本人或团体同意公开属于他人或团体的个人或机密信息</li>
        <li>
          以任何可能对Netfarmer的服务器，网络，计算机系统，资源造成损坏，禁用，超负荷，损害或伤害的方式使用服务
        </li>
        <li>违反任何适用的地方、国家或国际法律法规</li>
        <li>创建虚假身份作为确认信息或发起通信来误导任何人</li>
      </ol>
      <h3>发送垃圾邮件或进行非法活动</h3>
      <p>
        您同意对使用Netfarmer服务传递的内容负全责。且您同意不使用Netfarmer服务作非法用途或传输非法、诽谤、骚扰、侵犯他人隐私、辱骂、威胁、危害、庸俗、色情、淫秽等信息，或令人反感、冒犯宗教感情、宣扬种族主义、含有病毒或恶意代码的信息，或会侵犯他人知识产权或其它权利的信息。您同意不使用Netfarmer服务发送垃圾邮件、“连锁信”
        、网络钓鱼邮件或未经请求的群发邮件。如发现有足够理由认为您使用Netfarmer服务进行任何非法活动或未经授权的活动，Netfarmer保留终止您的帐号、拒绝您使用部分或全部Netfarmer服务的权利。
      </p>
      <h3>不活跃用户账号政策</h3>
      <p>
        对免费用户帐号，若连续120天未登录Netfarmer服务，Netfarmer保留终止此类帐号的权利。终止后，相关数据也将被删除。我们会事先通知您此终止并提供备份数据选项。数据删除政策可能针对全部或部分服务进行。为计算未登录时间，每个服务将被视为独立、单独的服务。即，对一个服务的经常使用，并不代表对另外一个服务的经常使用。对于帐号里有一个以上的用户，如果至少一个用户经常使用，则不会认为该帐号是不经常使用的。
      </p>
      <h3>数据所有权</h3>
      <p>
        您自己创建或存储的内容所有权归您。除非经您特别允许，否则Netfarmer不会使用、复制、改编、修改或发布您帐号内创建或存储的内容以作商业、营销或任何类似目的之用。但是您授予Netfarmer的访问，复制，分发，存储，传输，重新格式化，公开展示，公开执行您的用户帐户的权限，仅限于为您提供服务所需。只有出于为您提供服务的目的，当您授权后，Netfarmer才有权访问、复制、存储、发送您的帐号内容。
      </p>
      <h3>商标</h3>
      <p>
        Netfarmer、Netfarmer的logo、每个Netfarmer服务的名称及Logo都是Netfarmer的注册商标。未经Netfarmer许可，禁止使用。
      </p>
      <h3>免责声明</h3>
      <p>
        您明确理解并同意，使用Netfarmer服务由您自行承担相关风险。Netfarmer服务仅依其当前所呈现的状况提供，Netfarmer明确表示不承担任何形式的保证，无论明示或暗示，包括但不限于对服务适合特定用途的担保。Netfarmer不保证服务会无中断、及时、安全，或错误。使用从Netfarmer服务下载或获得任何材料时，您应自己判断其风险，且全权负责可能会对您的计算机系统、手机、无线设备或数据所造成的任何损害。除本条款明确规定外，您从Netfarmer、其雇员或代表那里所得到的任何建议或信息，无论是书面还是口头的，均不应构成任何担保。
      </p>
      <h3>有限责任</h3>
      <p>
        在任何情况下，Netfarmer对任何损害概不负责，无论这些损害是否由于使用、不能使用Netfarmer服务所直接或间接造成，包括但不限于商业利润损失，业务中断，计算机故障，业务信息丢失等损失，也不管Netfarmer事前是否知道这类损害可能发生。在任何情况下，Netfarmer可承担的全部责任不会超过您已支付的服务费用。
      </p>
      <h3>赔偿</h3>
      <p>
        如果因您使用Netfarmer服务而侵害了其他人的权利、违法或违反了此条款中任何规定，由此造成或相关的任何损失、损害、罚款和费用（包括律师费和诉讼费）的索赔，您应进行赔偿，且Netfarmer、其管理人员、董事、雇员，供应商和附属公司，不对此有赔偿责任。
      </p>
      <h3>仲裁</h3>
      <p>
        任何由本条款引起的争议或与本条款相关的索赔应按照中国相关法律加以解决。任何此类争议或索赔应单独进行仲裁，并不得与其他方的索赔或争议进行合并仲裁。仲裁结果是决定性的，不可上诉。Netfarmer也可以在任何时间，从任何有管辖权的法院寻求禁令或其它形式的公平救济。
      </p>
      <h3>中断或终止</h3>
      <p>
        当用户帐号发生可疑非法活动、帐号休眠、政府或法律部门调查要求时，我们会中断或暂时禁止您访问Netfarmer服务。对用户账号中断和禁用的异议要在被通知中断30天内向
        <span className="email">support@netfarmer.com.cn</span>
        提出。我们有可能在30天后终止暂停或禁用的用户账号。我们也可能根据您的申请终止您的用户账号。
        此外，我们保留在确信您违反了此条款时终止您的用户账号和拒绝服务的权利，并在出现未预料的技术问题或停止Beta服务时，终止您对Beta服务的访问。如果Netfarmer违反了条款中的义务，您有权终止您的用户账号，在这种情况下您将有权获得预付费用的按比例退款。终止用户帐号包括拒绝其访问所有Netfarmer服务、删除其电子邮件地址和密码、以及帐号下的所有数据。
      </p>
      <h3>服务条款结束</h3>
      <p>
        如果您对本服务条款有任何问题或顾虑，请发送邮件至
        <span className="email">support@netfarmer.com.cn</span>。
      </p>
    </div>
  );
};

export default ServiceTerm;
