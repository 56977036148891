import React from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  message,
  Modal,
  Card,
  Row,
  Col,
} from "antd";
import request from "../../../utils/request";
import { useState } from "react";

const { Paragraph, Text } = Typography;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 8 },
};

const Configuration = ({ token, url }) => {
  const [saveDisable, setSaveDisable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    request("/middle/config", { method: "POST", data: values }).then((res) => {
      if (res.code === 200) {
        message.success("保存成功");
      } else {
        message.error("保存失败");
      }
      setTimeout(() => window.location.reload(), 1000);
    });
  };

  const onTestConnection = () => {
    const param = form.getFieldsValue();
    request("/middle/test-connect", { method: "POST", data: param }).then(
      (res) => {
        if (res.code === 200) {
          message.success("连接成功", 3);
          setSaveDisable(false);
        } else {
          message.error("连接失败", 3);
        }
      }
    );
  };

  function editConfig() {
    form.setFieldsValue({ access_token: token, url: url });
    setVisible(true);
  }

  return (
    <div className="site-page-header-ghost-wrapper" style={{ marginTop: 20 }}>
      {token && url ? (
        <Paragraph>
          <blockquote>
            <Text strong>中间库地址:</Text>
            <Paragraph>{url}</Paragraph>
            <Text strong>系统访问凭证:</Text>
            <Paragraph>{token}</Paragraph>
          </blockquote>
          <Button type="primary" onClick={editConfig}>
            修改
          </Button>
        </Paragraph>
      ) : (
        <Row gutter={16}>
          <Col span={8}>
            <Card size="small">
              <Typography.Title level={4}>没有配置</Typography.Title>
              <Button type="primary" onClick={() => setVisible(true)}>
                添加配置
              </Button>
            </Card>
          </Col>
        </Row>
      )}

      <Modal
        title="中间库配置"
        okText="保存"
        cancelText="取消"
        visible={visible}
        width={780}
        onCancel={() => setVisible(false)}
        onOk={() => form.submit()}
        okButtonProps={{ disabled: saveDisable }}
      >
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item
            name="url"
            label="中间库地址"
            rules={[{ required: true, message: "中间库地址必填" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="access_token"
            label="访问凭证"
            rules={[{ required: true, message: "访问凭证必填" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={onTestConnection}
            >
              测试连接
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Configuration;
