import React, {useEffect, useState} from "react";

import { useHistory } from 'react-router-dom'

import ServiceDetail from  "../../../components/ServiceDetail"
import request from "../../../utils/request";
import Configuration from "./Configuration";


import qs from "query-string"
import { message } from "antd";

const AlreadyPurchased = (props) => {

    const history = useHistory()
    const [service, setService] = useState({})
    useEffect(()=> {
        const queryParam = qs.parse(history.location.search)
        if(queryParam['code'] && queryParam['accounts-server']){
            const id = localStorage.getItem("zoho_record_id");
            request(`/zoho-token/auth/${id}`, { method: 'POST', data: queryParam }).then(res => {
                if(res.code === 200){
                    message.success("授权成功")
                }else{
                    message.error("授权失败")
                }
                localStorage.removeItem("zoho_record_id")
            })
        }
        request('/already-purchased/detail',{params: {route_path: 'zoho-token'}}).then(res => {
            if(res.code === 200){
                setService(res.data)
            }
        })
    }, [])
  return(
      <div>
          <ServiceDetail service={service} />
          <Configuration /> 

      </div>
  )
}
export default AlreadyPurchased