import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Card, Descriptions, message, Modal, PageHeader, Statistic, Tag} from "antd";
import {FallOutlined} from '@ant-design/icons'

import './index.css'
import request from "../../utils/request";

const {Countdown} = Statistic

const ServiceDetail = ({service}) => {

    const history = useHistory();
    const [isVisible, setVisible] = useState(false)

    function generateOrder() {
        request("/order", {method: "post", data: {route_path: service.routeName}}).then(res => {
            if (res.code === 200) {
                message.success("订单创建成功");
                history.push({
                    pathname: "/pay",
                    state: {order: res.data}
                })
            }
        })
    }

    return (
        <>
            <div className={"site-page-header-ghost-wrapper"}>
                <PageHeader title={service.app_name} tags={<Tag color="blue">Running</Tag>}
                            extra={[<Button type="primary" onClick={() => setVisible(true)}>续费</Button>]}>
                    <Descriptions size="small" column={3}>
                        <Descriptions.Item label="开始时间">{service.start_time}</Descriptions.Item>
                        <Descriptions.Item label="到期时间">
                            {service.end_time}
                        </Descriptions.Item>
                        <div
                            className="extra"
                            style={{
                                display: 'flex',
                                width: 'max-content',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Countdown prefix={<FallOutlined/>} title="剩余天数" value={service.end_time} format="D 天"/>
                        </div>
                    </Descriptions>
                </PageHeader>
                <Modal footer={null} width={500} visible={isVisible} onCancel={() => setVisible(false)}>

                    <Card className="pay-card">

                        <div className="clearfix">
                            <header>
                                <span className="product-name">{service.app_name} - 年付</span>
                            </header>
                            <div className="product-pricing">
                                起价
                                <span className="order-price">&yen;{service.price}RMB</span>每年
                                <br/>
                            </div>
                            <footer className="pay-footer">
                                        <span
                                            className="btn-order"
                                            onClick={generateOrder}
                                        >
                                            生成订单
                                        </span>
                            </footer>
                        </div>

                    </Card>
                </Modal>
            </div>
        </>
    )

}

export default ServiceDetail;