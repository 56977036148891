import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import MDEditor from '@uiw/react-md-editor';
import {Button, Modal, Card, message} from "antd";
import "./index.css";
import request from "../../utils/request";

const ApplicationIntroduction = ({application}) => {
    const [isVisible, setVisible] = useState(false)
    const history = useHistory();

    function generateOrder() {
        request("/order", {method: "post", data: {route_path: application.route_path}}).then(res => {
            if (res.code === 200) {
                message.success("订单创建成功");
                history.push({
                    pathname: "/pay",
                    state: {order: res.data}
                })
            }
        })
    }

    function freeTrial(){
        request(`/application/${application.route_path}/trial`, {method: 'POST'}).then((res) => {
            if (res.code === 200) {
                message.success("试用成功");
            } else {
                message.error("试用失败");
            }
            setTimeout(() => window.location.reload(), 1000);
        });
    }

    return (
        <div className="cont">
            <MDEditor.Markdown source={application.description}/>
            <div className="btn">
                <Button type="primary" onClick={() => setVisible(true)}>立即开通</Button>
            </div>
            <Modal footer={null} width={500} visible={isVisible} onCancel={() => setVisible(false)}>

                <Card className="pay-card">

                    <div className="clearfix">
                        <header>
                            <span className="product-name">{application.name} - 年付</span>
                        </header>
                        <div className="product-pricing">
                            起价
                            <span className="order-price">&yen;{application.price}RMB</span>每年
                        </div>
                        <footer className="pay-footer">
                                        <span
                                            className="btn-order"
                                            onClick={generateOrder}
                                        >
                                            生成订单
                                        </span>
                            <span className="free-btn" onClick={freeTrial}>免费试用</span>
                        </footer>
                    </div>
                </Card>
            </Modal>
        </div>
    );
};

export default ApplicationIntroduction;
