import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DashboardOutlined,
  AppstoreOutlined,
  HeartTwoTone,
  BellOutlined,
  LoginOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  Badge,
  Divider,
  Button,
  Breadcrumb,
} from 'antd';
import './BaseLayout.less';

import { getBreadcrumb } from '../../utils/utils';

import logo from './logo.png';
import request from '../../utils/request';

const { SubMenu } = Menu;
const { Header, Sider, Content, Footer } = Layout;

const helpDetail = (
  <div>
    <div>售前电话：400-998-6877</div>
    <div>售前联系：sales@netfarmer.com.cn</div>
  </div>
);

const BaseLayout = (props) => {
  const { children, location } = props
  console.log(props,'----props---');
  const [currentUser, setCurrentUser] = useState({});
  const [applications, setApplications] = useState([]);

  const breadcrumbs = getBreadcrumb(location.pathname, applications)
  let default_key = breadcrumbs[0];
  let openKey = '';
  if (location.pathname.includes('application') ||location.pathname.includes('order_app')) {
    default_key = breadcrumbs[1];
    openKey = '应用';
  }
  const breadcrumbItem = breadcrumbs.map((item) => (
    <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
  ));

  function onAvatarMenuClick({ key }) {
    if ('logout' === key) {
      localStorage.removeItem('access-token');
      window.location.href = '/';
    } else {
      window.location.href = `/${key}`;
    }
  }

  const avatarMenu = (
    <Menu onClick={onAvatarMenuClick}>
      <Menu.Item key='personal-center'>
        <SettingOutlined />
        <span className='menuItem'>个人设置</span>
      </Menu.Item>
      <Menu.Item key='order-center'>
        <ShoppingCartOutlined />
        <span className='menuItem'>订单中心</span>
      </Menu.Item>
      <Menu.Item key='invoice-management'>
        <ContainerOutlined />
        <span className='menuItem'>发票管理</span>
      </Menu.Item>
      <Menu.Item key='system-log'>
        <BellOutlined />
        <Badge
          dot={
            (currentUser && currentUser.unread_message_quantity) > 0
              ? true
              : false
          }
        >
          <span className='menuItem'>系统日志</span>
        </Badge>
      </Menu.Item>
      <Divider style={{ margin: 5 }} />
      <Menu.Item key='logout'>
        <LoginOutlined />
        <span className='menuItem'>退出登录</span>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    request('/user/current_user').then((res) => {
      if (res.code === 200) {
        request('/application/menu').then((res1) => {
          if (res1.code === 200) {
            const { applications, services } = res1.data;
            services.forEach((element) => {
              applications.forEach((app) => {
                if (app['id'] === element['app_id']) {
                  app['isPayment'] = true;
                }
              });
            });
            setCurrentUser(res.data);
            setApplications(applications);
          }
        });
      }
    });
  }, []);
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider breakpoint='lg' collapsedWidth='0'>
        <div className='logo'>
          <a href='https://connector.netfarmer.com.cn'>
            <img alt='logo' src={logo} />
            <span>Connector</span>
          </a>
        </div>
        {/* <div className="logo" /> */}
        <Menu
          theme='dark'
          mode='inline'
          selectedKeys={[default_key]}
          defaultOpenKeys={[openKey]}
        >
          <Menu.Item
            key='仪表盘'
            title='Dashboard'
            icon={<DashboardOutlined />}
          >
            <Link to='/Dashboard'>仪表盘</Link>
          </Menu.Item>
          <SubMenu key='应用' icon={<AppstoreOutlined />} title='应用中心'>
            {applications.map((application) => {
              return (
                <Menu.Item key={application.name}>
                  <Link to={`/application/${application.route_path}`}>
                    {application.name}
                    <span style={{ float: 'right' }}>
                      {' '}
                      {application.isPayment ? (
                        <HeartTwoTone twoToneColor='#eb2f95' />
                      ) : null}
                    </span>
                  </Link>
                </Menu.Item>
              );
            })}
          </SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className={'site-layout-sub-header-background'}
          style={{ padding: 0, background: 'white' }}
        >
          <span style={{ float: 'right', marginRight: 20 }}>
            <Dropdown overlay={avatarMenu} className={'avatarMenu'}>
              <Button type='link'>
                <Avatar
                  src='https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'
                  alt='头像占位符'
                />
                <Badge
                  count={currentUser ? currentUser.unread_message_quantity : 0}
                  overflowCount={99}
                  offset={[5, -10]}
                >
                  <span
                    style={{
                      marginLeft: 3,
                      fontSize: '14px',
                    }}
                  >
                    {currentUser.name}
                  </span>
                </Badge>
              </Button>
            </Dropdown>
          </span>
        </Header>
        <Breadcrumb style={{ margin: 15 }}>{breadcrumbItem}</Breadcrumb>
        <Content
          key={location.pathname}
          style={{ margin: '0 16px', backgroundColor: 'white' }}
        >
          <div style={{ padding: 24 }}>{children}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          {helpDetail}
          Connector System ©2021 Created by 上海旺田信息科技有限公司
        </Footer>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
