import React, {useState, useEffect} from "react";
import {
    Table,
    Button,
    Drawer,
    Space,
    Form,
    Col,
    Row,
    Input,
    Select,
    Tag,
    Divider,
    message,
    Modal,
    Typography
} from "antd";
import {PlusOutlined} from "@ant-design/icons";

import request from "../../../utils/request";

const {Option} = Select;
const {confirm} = Modal;
const {Paragraph} = Typography;

const Configuration = (props) => {
    const [form] = Form.useForm();
    const [settings, setSettings] = useState([]);
    const [record, setRecord] = useState({});
    const [showDrawer, setShowDrawer] = useState(false);
    const columns = [
        {
            title: "Zoho 版本",
            key: "zoho_version",
            dataIndex: "zoho_version",
            width: 150,
            fixed: "left",
            render: (text, record, index) => {
                if (text === "china") {
                    return <Tag color="magenta">中国版</Tag>;
                } else {
                    return <Tag color="orange">国际版</Tag>;
                }
            },
        },
        {
            title: "Client_Id",
            dataIndex: "client_id",
            width: 300,
        },
        {
            title: "Client_Secret",
            dataIndex: "client_secret",
            width: 300,
        },
        {
            title: "作用域",
            dataIndex: "scope",
            width: 300,
            render: (text, record, index) => {
                return record.scope.map((item) => {
                    return <Tag color="cyan">{item}</Tag>;
                });
            },
        },
        {
            title: "令牌地址",
            dataIndex: "token_url",
            width: 300,
            render: (text) =>
                <Paragraph copyable={{tooltip: ["复制", "复制成功"]}}>
                    {text}
                </Paragraph>
        },
        {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 150,
            render: (text, record, index) => {
                return (
                    <span>
            <Button
                style={{padding: 0}}
                type="link"
                onClick={() => deleteBase(record)}
                danger
            >
              删除
            </Button>
            <Divider type="vertical"/>
            <Button
                type="link"
                style={{padding: 0}}
                onClick={() => editBase(record)}
            >
              编辑
            </Button>
            <Divider type="vertical"/>
            <Button
                type="link"
                style={{padding: 0}}
                onClick={() => generateAuthUrl(record)}
            >
              授权
            </Button>
          </span>
                );
            },
        },
    ];
    useEffect(() => {
        request("/zoho-token/settings").then((res) => {
            if (res.code === 200) {
                setSettings(res.data);
            }
        });
    }, []);

    function generateAuthUrl(record) {
        localStorage.setItem("zoho_record_id", record.id);
        const url = `https://accounts.zoho.${record.zoho_version === "china" ? 'com.cn' : 'com'}/oauth/v2/auth?scope=${record.scope}&client_id=${record.client_id}&response_type=code&access_type=offline&redirect_uri=http%3A%2F%2Fapp.netfarmer.com.cn%2Fapplication%2Fzoho-token`;
        window.open(url, "授权页面", "_blank");
    }

    function editBase(record) {
        setRecord(record);
        form.setFieldsValue(record);
        setShowDrawer(true);
    }

    function deleteBase(record) {
        confirm({
            title: "是否确定删除此条记录？",
            content: "删除成功后，将无法获取该条记录的Access Token。",
            cancelText: "否",
            okText: "是",
            onOk: () => {
                request(`/zoho-token/setting/${record.id}`, {method: "DELETE"}).then(
                    (res) => {
                        if (res.code === 200) {
                            message.success("删除成功");
                        } else {
                            message.error("删除失败");
                        }
                        setTimeout(() => window.location.reload(), 1000);
                    }
                );
            },
        });
    }

    function onFinish(values) {
        request("/zoho-token/setting", {method: "POST", data: values}).then((res) => {
            if (res.code === 200) {
                message.success("保存成功");
            } else {
                message.error("保存失败");
            }
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        });
    }

    return (
        <div className="site-page-header-ghost-wrapper" style={{marginTop: 20}}>
            <Button
                type="primary"
                onClick={() => setShowDrawer(true)}
                style={{marginBottom: 15}}
            >
                <PlusOutlined/> ZoHo配置
            </Button>

            <Typography style={{display: 'inline'}}>
                <a style={{marginLeft: 10}}
                   href="https://www.yuque.com/docs/share/797386fb-885a-4a3d-91de-fa7d881c7b13?# 《Zoho令牌》"
                   rel="noreferrer" target="_blank">配置教程</a>
            </Typography>

            <Table columns={columns} dataSource={settings} rowKey="id" scroll={{x: 1080, y: 500}}/>
            <Drawer
                onClose={() => {
                    setShowDrawer(false);
                    form.resetFields();
                }}
                visible={showDrawer}
                width={520}
                title="zoho 配置"
                footer={
                    <div style={{textAlign: "right"}}>
                        <Space>
                            <Button
                                onClick={() => {
                                    setShowDrawer(false);
                                    form.resetFields();
                                }}
                            >
                                取消
                            </Button>
                            <Button type="primary" onClick={() => form.submit()}>
                                保存
                            </Button>
                        </Space>
                    </div>
                }
            >
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="id" hidden></Form.Item>
                            <Form.Item
                                name="zoho_version"
                                label="ZoHo 版本"
                                rules={[{required: true, message: "请选ZoHo的版本"}]}
                            >
                                <Select placeholder="请选ZoHo的版本">
                                    <Option value="china">中国版</Option>
                                    <Option value="international">国际版</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={20}>
                            <Form.Item
                                name="client_id"
                                label="Client_Id"
                                rules={[{required: true, message: "请输入您的Client_Id"}]}
                            >
                                <Input placeholder="请输入Client_Id"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={20}>
                            <Form.Item
                                name="client_secret"
                                label="Client_Secret"
                                rules={[{required: true, message: "请输入您的Client_Secret"}]}
                            >
                                <Input placeholder="请输入Client_Secret"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={20}>
                            <Form.Item
                                name="scope"
                                label="作用域"
                                rules={[{required: true, message: "Please choose the type"}]}
                            >
                                <Select mode="tags" placeholder="Please choose the type">
                                    <Option value="ZohoCRM.modules.ALL">
                                        ZohoCRM.modules.ALL
                                    </Option>
                                    <Option value="BackOffice.modules.ALL">
                                        BackOffice.modules.ALL
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    );
};

export default Configuration;
