import React, { useState, useEffect } from "react";

import {
  Button,
  Table,
  Tooltip,
  Drawer,
  Divider,
  Form,
  Col,
  Row,
  Input,
  Typography,
  Popconfirm,
  message,
  Modal,
  Dropdown,
  Space,
  Menu,
  Tag,
  TimePicker,
  InputNumber,
} from "antd";

import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import moment from "moment";

import request from "../../../utils/request";

const { Paragraph } = Typography;
const { confirm } = Modal;
const text = "警告：没有特殊原因请勿私自更换公钥！";

// 企业微信ID、Key ...配置页面
const Configuration = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const [publicKey, setPublicKey] = useState();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [setting, setSetting] = useState({});
  const [keyButton, setKeyButton] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [matchingModel, setMatchingModel] = useState(false)

  const [form] = Form.useForm();

  const onClick = (key, id) => {
    confirm({
      title: "你确定要同步吗?",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        request(`/wecom/sync/${id}?sync_type=${key.key}`, {
          method: "POST",
        }).then((res) => {
          if (res.code === 200) {
            message.info("同步正在后台进行...");
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  const columns = [
    {
      title: "企业ID",
      dataIndex: "corp_id",
      key: "corp_id",
      ellipsis: true,
      fixed: "left",
      width: 250,
    },
    {
      title: "token",
      dataIndex: "token",
      key: "token",
      width: 300,
      render: (text) => (
        <Paragraph copyable={{ tooltip: ["复制", "复制成功"] }}>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "encodingAESkey",
      dataIndex: "encoding_aes_key",
      key: "encoding_aes_key",
      width: 300,
      render: (text) => (
        <Paragraph copyable={{ tooltip: ["复制", "复制成功"] }}>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "回调地址",
      dataIndex: "callback_url",
      key: "callback_url",
      width: 300,
      render: (text) => (
        <Paragraph copyable={{ tooltip: ["复制", "复制成功"] }}>
          {text}
        </Paragraph>
      ),
    },
    {
      title: "每天同步开始时间",
      dataIndex: "sync_time",
      key: "sync_time",
      width: 200,
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "同步间隔时间（h)",
      dataIndex: "interval",
      key: "interval",
      width: 150,
      render: (text) => <Tag color="cyan">{text}</Tag>,
    },
    {
      title: "通讯录Secret",
      dataIndex: "staff_secret",
      key: "staff_secret",
      ellipsis: true,
      width: 300,
      onCell: () => {
        return {
          style: {
            maxWidth: 150,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            cursor: "pointer",
          },
        };
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "客户联系Secret",
      dataIndex: "client_secret",
      key: "client_secret",
      ellipsis: true,
      width: 300,
      onCell: () => {
        return {
          style: {
            maxWidth: 150,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            cursor: "pointer",
          },
        };
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "会话存档Secret",
      dataIndex: "chat_secret",
      key: "chat_secret",
      ellipsis: true,
      width: 300,
      onCell: () => {
        return {
          style: {
            maxWidth: 150,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            cursor: "pointer",
          },
        };
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "操作",
      align: "center",
      fixed: "right",
      width: 250,
      render: (text, row, index) => {
        return (
          <span>
            <Button
              size="small"
              style={{ padding: 0 }}
              type="link"
              onClick={() => deleteBase(row)}
              danger
            >
              删除
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => editBase(row)}
            >
              编辑
            </Button>
            <Divider type="vertical" />
            <Space size="middle">
              <Dropdown
                overlay={
                  <Menu onClick={(key) => onClick(key, row.id)}>
                    <Menu.Item key="all">一键同步</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="client">同步客户</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="chat">同步聊天记录</Menu.Item>
                  </Menu>
                }
              >
                <a
                  className="ant-dropdown-link"
                  style={{ padding: 0 }}
                  rel="noreferrer"
                >
                  同步
                  <DownOutlined />
                </a>
              </Dropdown>
            </Space>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    request("/wecom/settings").then((res) => {
      if (res.code === 200) {
        setLoading(false);
        setSettings(res.data);
      }
    });
  }, [id]);

  const showDrawer = () => {
    // window.location.href = '/initialize_configuration'
    form.resetFields();
    setPublicKey(null);
    setVisible(true);
    setSetting(null);
  };

  const getPublicKey = () => {
    setKeyButton(true);
    request("/wecom/getKey").then((res) => {
      if (res.code === 200) {
        message.success("创建成功");
        form.setFieldsValue({ ...res.data });
        setPublicKey(res.data.public_key);
        setKeyButton(false);
      }
    });
  };

  const onFinish = (values) => {
    if (values["sync_time"]) {
      values["sync_time"] = moment(values["sync_time"]).format("HH:mm");
    }
    setSaveButton(true);
    if (setting) {
      request(`/wecom/settings/${setting.id}`, {
        method: "PUT",
        data: values,
      }).then((res) => {
        if (res.code === 200) {
          message.success("保存成功");
        } else {
          message.error("保存失败");
        }
      });
    } else {
      request("/wecom/settings", { method: "POST", data: values }).then(
        (res) => {
          if (res.code === 200) {
            message.success("保存成功");
          } else {
            message.error("保存失败");
          }
        }
      );
    }
    setSaveButton(false);
    setTimeout(() => window.location.reload(), 1000);
  };

  const editBase = (row) => {
    if (row) {
      form.setFieldsValue(row);
      setSetting(row);
      setVisible(true);
      setPublicKey(row.public_key);

      if (row.sync_time && row.interval) {
        form.setFieldsValue({
          sync_time: moment(row.sync_time, "HH:mm"),
          interval: row.interval,
        });
      }
    }
  };

  const deleteBase = (row) => {
    confirm({
      title: "确定要删除吗？",
      content: "删除后可能会影响回调同步。",
      cancelText: "取消",
      okText: "确定",
      onOk: () => {
        request(`/wecom/settings/${row.id}`, { method: "DELETE" }).then(
          (res) => {
            if (res.code === 200) {
              message.success("删除成功!");
              setTimeout(() => window.location.reload(), 1000);
            }
          }
        );
      },
    });
  };

  function onChange(value) {
    const time = moment(form.getFieldValue("sync_time")).hours();
    if (value + time >= 24) {
      message.error("下一次同步时间超过今天");
      form.setFieldsValue({ sync_interval: null });
    }
  }

  const openMatchingModel = () => {
      setMatchingModel(true)
  }

  return (
    <div className="site-page-header-ghost-wrapper" style={{ marginTop: 20 }}>
      <div style={{ marginBottom: 10 }}>
        <Button type="primary" onClick={showDrawer}>
          <PlusOutlined /> 企业微信配置
        </Button>
        <Typography style={{ display: "inline" }}>
          <a
            style={{ marginLeft: 10 }}
            href="https://www.yuque.com/docs/share/6c5f2fdb-b8ba-4ea6-9dbe-9087bae8448e?# 《企业微信》"
            rel="noreferrer"
            target="_blank"
          >
            配置教程
          </a>
        </Typography>
        <Drawer
          title="添加企业微信配置"
          width={720}
          onClose={() => {
            setVisible(false);
          }}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
          forceRender={true}
          footer={
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  setVisible(false);
                }}
                style={{ marginRight: 10 }}
              >
                取消
              </Button>
              <Button
                type="primary"
                loading={saveButton}
                onClick={() => form.submit()}
              >
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="企业ID"
                  name="corp_id"
                  rules={[{ required: true, message: "不能为空" }]}
                >
                  <Input placeholder="请输入企业ID" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="通讯录Secret"
                  name="staff_secret"
                  rules={[{ required: true, message: "不能为空" }]}
                >
                  <Input placeholder="请输入通讯录Secret" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="客户联系Secret"
                  name="client_secret"
                  rules={[{ required: true, message: "不能为空" }]}
                >
                  <Input placeholder="请输入客户联系Secret" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="会话内容存档Secret"
                  name="chat_secret"
                  rules={[{ required: true, message: "不能为空" }]}
                >
                  <Input placeholder="请输入会话内容存档Secret" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="公钥"
                  name="public_key"
                  rules={[{ required: true, message: "不能为空" }]}
                >
                  <Paragraph copyable={{ tooltip: ["复制", "复制成功"] }}>
                    {publicKey ? (
                      publicKey
                    ) : (
                      <span style={{ color: "red" }}>请获取公钥</span>
                    )}
                  </Paragraph>
                  {!publicKey ? (
                    <Button onClick={getPublicKey} loading={keyButton}>
                      获取公钥
                    </Button>
                  ) : (
                    <Popconfirm
                      placement="top"
                      title={text}
                      okText="确定"
                      onConfirm={getPublicKey}
                      cancelText="取消"
                    >
                      <Button>刷新公钥</Button>
                    </Popconfirm>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="同步时间" name="sync_time">
                  <TimePicker
                    style={{ width: 200 }}
                    format="HH:mm"
                    placeholder="请选择每天初次同步时间"
                  />
                </Form.Item>
                <Form.Item name="interval" label="同步间隔时间">
                  <InputNumber
                    style={{ width: 200 }}
                    min={1}
                    max={23}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="private_key">
                  <Input.TextArea hidden />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
        <Table
          rowKey="id"
          columns={columns}
          size="middle"
          loading={loading}
          dataSource={settings}
          pagination={false}
          scroll={{ x: 700, y: 500 }}
        />
      </div>
      <Button onClick={openMatchingModel}>匹配</Button>

      <Modal
        title="Modal 1000px width"
        centered
        visible={matchingModel}
        onOk={() => setMatchingModel(false)}
        onCancel={() => setMatchingModel(false)}
        width={1000}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
      </Modal>
    </div>
  );
};

export default Configuration;
